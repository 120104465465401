<template>
  <section class="section">
    <h4 class="title has-text-centered is-4">Patreon Unsynced</h4>
    <b-table
      :data="unsynced"
      :striped="true"
      :hoverable="true"
      :loading="loadingUnsynced"
      class="mt-4 clickable-table user-table"
      @click="syncUser"
    >
      <b-table-column field="created_at" label="Date" v-slot="props">
        <p>
          {{ props.row.created_at | dateStandard }}
        </p>
      </b-table-column>

      <b-table-column field="email" label="Email" v-slot="props">
        <p>{{ props.row.email }}</p>
      </b-table-column>

      <b-table-column field="full_name" label="Name" v-slot="props">
        <p>{{ props.row.full_name }}</p>
      </b-table-column>

      <b-table-column field="tier_amount" label="Amount" v-slot="props">
        <p>
          <strong>${{ props.row.tier_amount / 100 }}</strong>
        </p>
      </b-table-column>

      <b-table-column field="patreon_id" label="Patreon ID" v-slot="props">
        <p>{{ props.row.patreon_id }}</p>
      </b-table-column>
    </b-table>
  </section>
</template>

<script>
import { mapState } from 'vuex';
import { Patreon } from '@/services/api';

export default {
  name: 'Unsynced',
  computed: {
    ...mapState('patreon', ['loadingUnsynced', 'unsynced']),
  },
  mounted() {
    this.loadUnsynced();
  },
  methods: {
    loadUnsynced() {
      this.$store.dispatch('patreon/loadUnsynced');
    },
    syncUser(row) {
      this.$buefy.dialog.prompt({
        message: `Who is the user for ${row.email} (${row.full_name})?`,
        inputAttrs: {
          placeholder: 'User email in DB',
        },
        trapFocus: true,
        closeOnConfirm: false,
        onConfirm: (value, { close }) => {
          Patreon.syncUser(value, row.email)
            .then(() => {
              this.$buefy.toast.open({
                message: 'Patreon synced successfully!',
                type: 'is-success',
              });
            })
            .catch(() => {
              this.$buefy.toast.open({
                message: 'Patreon could not be synced!',
                type: 'is-danger',
              });
            })
            .finally(() => {
              this.loadUnsynced();
              close();
            });
        },
      });
    },
  },
};
</script>

<style>
.user-table {
  font-size: 12px;
}
</style>
